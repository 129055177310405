body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.objc-h100{
  object-fit: cover;
  height: 100% !important;
}

input[type="file"] {
  margin-left: -2px !important;
}

.custom-file-button input[type="file"]::-webkit-file-upload-button {
  display: none;
}

.custom-file-button input[type="file"]::file-selector-button {
  display: none;
}

.custom-file-button:hover label {
  background-color: #dde0e3;
  cursor: pointer;
}
.file-upload-wrapper {
  position: relative;
  display: inline-block;
}

.file-input {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}

.file-label {
  display: inline-block;
  padding: 10px 15px;
  background-color: #4CAF50;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  user-select: none;
}

.file-input:hover + .file-label,
.file-label:hover {
  background-color: #45a049;
}

